<template>
  <!-- <div class="top-margin" style="margin-top: 84px !important"> -->

  <v-app>
    <nav v-if="userData.isAuthenticated && !hideHeader" class="navbar navbar-expand-sm fixed-top navbar-eaztimate">
      <v-container fluid>
        <a class="navbar-brand" href="https://ezadmin.se" target="_blank">
          <svg
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style="enable-background: new 0 0 24 24; width: 24px; height: 24px"
            xml:space="preserve"
          >
            <g id="Ez">
              <path
                class="st0"
                d="M12.24,1.12c6.14,0,11.13,4.99,11.13,11.14c0,6.14-4.99,11.14-11.13,11.14c-6.14,0-11.14-5-11.14-11.14
		C1.11,6.11,6.1,1.12,12.24,1.12 M12.24,21.98c5.36,0,9.72-4.36,9.72-9.72c0-5.36-4.36-9.72-9.72-9.72c-5.36,0-9.72,4.36-9.72,9.72
		C2.52,17.61,6.88,21.98,12.24,21.98"
              />
              <path class="st0" d="M18.47,12.84c0,3.44-2.79,6.23-6.23,6.23c-3.44,0-6.23-2.79-6.23-6.23H18.47z" />
            </g>
          </svg>
        </a>
        <ul class="ez-navbar pl-0">
          <router-link tag="li" to="/start" class="nav-item" active-class="active">
            <a v-if="userData.roles.includes('se.eaztimate.ska')" class="nav-link">Start</a>
          </router-link>
          <router-link tag="li" to="/ska" class="nav-item" active-class="active">
            <a v-if="userData.roles.includes('se.eaztimate.ska')" class="nav-link">Ärenden</a>
          </router-link>

          <router-link
            v-if="
              userData.roles.includes('se.eaztimate.ska.admin') ||
              userData.roles.includes('se.eaztimate.ska.kvalitet') ||
              userData.roles.includes('admin') ||
              userData.roles.includes('superadmin') ||
              userData.roles.includes('groupadmin')
            "
            tag="li"
            to="/admin/dashboard"
            class="nav-item"
            active-class="active"
          >
            <a class="nav-link">Dashboard</a>
          </router-link>
          <li class="nav-item">
            <a href="https://support.ezadmin.se" class="nav-link">Support</a>
          </li>
          <li>
            <span v-if="userData.roles.includes('se.eaztimate.ska.dev')" class="role-tag"
              ><i class="fas fa-code"></i> <span class="d-none d-md-inline"> DEV</span></span
            >
            <span v-if="userData.roles.includes('se.eaztimate.ska.alfa')" class="role-tag"
              ><i class="fas fa-atom"></i><span class="d-none d-md-inline"> Alfa</span></span
            >
            <span v-if="userData.roles.includes('se.eaztimate.ska.beta')" class="role-tag"
              ><i class="fas fa-flask"></i><span class="d-none d-md-inline"> Beta</span></span
            >
          </li>
          <li class="ez-navbar-signout" style="position: absolute; right: 0">
            <router-link to="/signout" class="d-inline-block float-right scrollcol-signout role-tag"
              ><i class="fas fa-sign-out-alt"></i
              ><span class="d-none d-md-inline-block">{{ userData.user.profile.name }}</span></router-link
            >
          </li>
        </ul>

        <!-- <ul class="navbar-nav justify-content-start pl-0">
                            <router-link tag="li" to="/start" class="nav-item" active-class="active">
                                <a class="nav-link" v-if="userData.roles.includes('se.eaztimate.ska')">Start</a>
                            </router-link>                           
                            <router-link tag="li" to="/ska" class="nav-item" active-class="active">
                                <a class="nav-link" v-if="userData.roles.includes('se.eaztimate.ska')">Ärenden</a>
                            </router-link>

                            <router-link tag="li" to="/admin/dashboard" class="nav-item" active-class="active" v-if="userData.roles.includes('se.eaztimate.ska.admin') || userData.roles.includes('se.eaztimate.ska.kvalitet') || userData.roles.includes('admin') || userData.roles.includes('superadmin') || userData.roles.includes('groupadmin')">
                                <a class="nav-link">Dashboard</a>
                            </router-link>
                            <li>
                                <span v-if="userData.roles.includes('se.eaztimate.ska.dev')" class="role-tag"><i class="fas fa-code"></i> <span class="d-none d-md-inline"> DEV</span></span>
                                <span v-if="userData.roles.includes('se.eaztimate.ska.alfa')" class="role-tag"><i class="fas fa-atom"></i><span class="d-none d-md-inline"> Alfa</span></span>
                                <span v-if="userData.roles.includes('se.eaztimate.ska.beta')" class="role-tag"><i class="fas fa-flask"></i><span class="d-none d-md-inline"> Beta</span></span>
                            </li>
                            <li>
                                <router-link to="/signout" class="d-inline-block d-md-none float-right scrollcol-signout" style="    padding: 10px 10px 10px 0px;"><i class="fas fa-sign-out-alt"></i></router-link>
                            </li>
                        </ul> -->
        <!-- <ul class="navbar-nav justify-content-end pl-0">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ userData.user.profile.name }}</a>
                                <div class="dropdown-menu" aria-labelledby="dropdown01">
                                    <a class="dropdown-item" v-if="userData.roles.some((i) => i[0] == 'admin')" @click="alertMessageForRole('admin')" style="display: none">Endast om 'admin'</a>
                                    <a class="dropdown-item" v-if="userData.roles.some((i) => i[0] == 'user')" @click="alertMessageForRole('user')" style="display: none">Endast om 'user'</a>
                                    <a class="dropdown-item" v-if="userData.roles.some((i) => i[0] == 'other')" @click="alertMessageForRole('other')" style="display: none">Endast om 'other'</a>
                                    <router-link to="/signout" class="dropdown-item">Logga ut</router-link>
                                </div>
                            </li>
                        </ul> -->
      </v-container>
    </nav>
    <nav v-if="userData.isAuthenticated && !hideSubHeader" class="fixed-under navbar-eaztimate">
      <div id="navbar2" class>
        <ul class="navbar-nav align-items-start pl-0">
          <slot name="navbar-frame"></slot>
          <router-link
            tag="li"
            :to="{ name: 'ska', query: { id: $route.query.id } }"
            class="nav-item"
            active-class="active"
          >
            <a class="nav-link">Skadedokumentation</a>
          </router-link>
          <router-link
            tag="li"
            :to="{ name: 'avf', query: { id: $route.query.id } }"
            class="nav-item"
            active-class="active"
          >
            <a class="nav-link">Avfuktning</a>
          </router-link>
          <router-link
            v-if="
              userData.roles.includes('se.eaztimate.ska.dev') &&
              (userData.roles.includes('se.eaztimate.ska.admin') ||
                userData.roles.includes('se.eaztimate.ska.kvalitet') ||
                userData.roles.includes('admin') ||
                userData.roles.includes('superadmin') ||
                userData.roles.includes('groupadmin'))
            "
            tag="li"
            :to="{ name: 'log', query: { id: $route.query.id } }"
            class="nav-item"
            active-class="active"
          >
            <a class="nav-link role-tag-color"><i class="fas fa-code" style="font-size: 0.75rem"></i> Loggar </a>
          </router-link>
        </ul>
      </div>
    </nav>
    <router-view />

    <!-- <main role="main">
            <alert :message="alertMessage" v-on:hide="alertMessage=null" />

            
            </main>-->
    <!-- <router-link tag="div" to="/list" class="mattias-knapp">Mattias-knapp</router-link> -->
  </v-app>
</template>

<script>
import store from "@/services/store";

export default {
  name: "Layout",
  components: {},
  data() {
    return {
      selected: 0,
      alertMessage: null,
      hideSubHeader: false,
      hideHeader: false,
    };
  },
  computed: {
    userData() {
      return store.state.userData;
    },
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Eaztimate - Ska";
      this.hideSubHeader = to.meta.hideSubHeader || false;
      this.hideHeader = to.meta.hideHeader || false;
    },
  },
  methods: {
    alertMessageForRole(v) {
      this.alertMessage = `Det här menyvalet är en TEST och visas endast ifall användaren har rollen &quot;${v}&quot;. Användbart ifall man vill begränsa något i UIt.<br><br>På serversidan, i EzCore så ska användarens inloggning kontrolleras genom att dekorera kontrollern eller metoden med Authorize-attributet. I TestController.TestPrivate2 finns exempel där även rollen kontrolleras.`;
    },
  },
};
</script>

<style>
@import "../assets/eaztimate.css";
@import "../assets/dark-layout.css";
@import "../assets/mobile.css";

/* #maincol,
#listcol {
    padding-top: 20px;
} */
html {
  height: 100%;
}

a {
  cursor: pointer;
}

.container h1 {
  margin-bottom: 1rem;
}

.container .row {
  text-align: left;
}

.table td,
.table th {
  text-align: left;
}

.navbar-dark .navbar-brand {
  font-size: 16px;
}
.nav-item.active {
  /* text-decoration: underline; */
  font-weight: 800;
}
.nav-item.active .nav-link {
  color: #000;
}
.navbar {
  box-shadow: 1px -19px 44px rgba(0, 0, 0, 0.1);
  padding: 0;
}
.navbar-nav {
  width: 100%;
  padding-left: 10px;
}
.navbar-toggler-icon {
  line-height: 26px;
}
/* .mattias-knapp {
    position: fixed;
    bottom: 40px;
    right: 40px;
    padding: 20px 40px;
    background-color: rgb(230, 63, 179);
    color: #fff;
    font-weight: bold;
    text-decoration: uppercase;
    font-size: 2rem;
    border-radius: 40px;
} */

.fixed-top {
  z-index: 200;
}

#navbar2 {
  display: flex;
  flex-basis: auto;
  padding: 0.2rem;
  background-color: #e6e6e6;
}
#navbar2 .navbar-nav {
  flex-direction: row;
}
#navbar2 .navbar-nav .nav-link {
  padding: 0.2rem 0.5rem;
}
.v-application ul {
  padding-left: 0;
}
</style>
