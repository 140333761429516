import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { configValue } from "@/services/config";
import { generateW3CId } from "@microsoft/applicationinsights-core-js";

let _instance;

export const VUE_APPLICATION_INSIGHTS = Symbol("VUE_APPLICATION_INSIGHTS");

function createApplicationInstanceVuePlugin(applicationInsights) {
  return {
    install: (app) => {
      // HACK: taken from provide(): https://github.com/vuejs/composition-api/blob/master/src/apis/inject.ts#L30
      app.mixin({
        beforeCreate() {
          // @ts-expect-error: TS2339
          if (!this._provided) {
            const provideCache = {};
            Object.defineProperty(this, "_provided", {
              get: () => provideCache,
              // eslint-disable-next-line no-setter-return
              set: (v) => Object.assign(provideCache, v),
            });
          }

          // @ts-expect-error: TS2339
          this._provided[VUE_APPLICATION_INSIGHTS] = applicationInsights;
        },
      });

      Object.defineProperty(app.prototype, "$appInsights", {
        get: () => applicationInsights,
      });
    },
  };
}

function resolveConfiguration() {
  return {
    applicationVersion: configValue("VUE_APP_APPLICATION_VERSION"),
    cloudRoleName: configValue("VUE_APP_AZURE_APP_INSIGHT_APP"),
    instrumentationKey: configValue("VUE_APP_AZURE_APP_INSIGHT_ID"),
  };
}

export function createApplicationInsights(store, router) {
  if (_instance) {
    throw new Error("ApplicationInsights already initialized");
  }

  const config = resolveConfiguration();

  _instance = new ApplicationInsights({
    config: {
      instrumentationKey: config.instrumentationKey,
      enableAjaxErrorStatusText: true,
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: ["googleapis.com"],
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

  _instance.loadAppInsights();
  _instance.addTelemetryInitializer(setupTelemetry(store, config));
  setupPageTracking(_instance, router, config.cloudRoleName);

  return {
    applicationInsights: _instance,
    applicationInsightsPlugin: createApplicationInstanceVuePlugin(_instance),
  };
}

function setupPageTracking(applicationInsights, router, cloudRoleName) {
  router.beforeEach((route, from, next) => {
    applicationInsights.context.telemetryTrace.traceID = generateW3CId();
    applicationInsights.context.telemetryTrace.name = route.name ?? undefined;
    applicationInsights.startTrackPage(cloudRoleName + " / " + route.name);
    next();
  });

  router.afterEach((route) => {
    const name = cloudRoleName + " / " + route.name;
    applicationInsights.stopTrackPage(name, location.href);
    applicationInsights.flush();
  });
}
function setupTelemetry(store, config) {
  return function (envelope) {
    if (envelope.tags) {
      envelope.tags["ai.cloud.role"] = config.cloudRoleName;
      envelope.tags["ai.application.ver"] = config.applicationVersion;
    }

    const authenticatedUserId = store.state.user.oidcUser?.profile.sub;
    const userRoles = store.state.user.roles || [];
    if (envelope.data) {
      envelope.data["companyId"] = store.getters.companyId;
      envelope.data["userProfession"] = store.getters.mainRole;
      envelope.data["userFeatureSet"] = userRoles.includes("se.eaztimate.hubb.beta") ? "beta" : "normal";
    }

    if (authenticatedUserId && envelope.tags) {
      envelope.tags["ai.user.authUserId"] = authenticatedUserId;
    }
  };
}

export function applicationInsightsInstance() {
  if (!_instance) {
    throw new Error("ApplicationInsights not initialized");
  }
  return _instance;
}
