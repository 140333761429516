import Vue from "vue";
import Vuex from "vuex";
import oidcmgr from "@/services/oidcmgr";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

const initialUserData = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  roles: [],
};

const store = new Vuex.Store({
  state: {
    userData: initialUserData,
  },
  mutations: {
    userDataUpdate(state, userData) {
      state.userData = userData;
    },
    userDataClear(state) {
      state.userData = initialUserData;
    },
    messageSet(state, message) {
      state.message = message;
    },
  },
  actions: {
    async userInit({ state, commit }) {
      // userInit is called on every route to avoid looking up the user from the store we can return fast
      //   - logout will be handled either manually or by the session monitor
      //   - tokens will be handled by the signinSilent
      // if we start to see failures with expired access tokens we could consider:
      //   - getting the oidc token before sending ajax requests
      //   - removing this early return to make the state more similar
      if (state.userData.isInitialized) {
        return;
      }

      try {
        const user = await oidcmgr.getUser();

        if (user != null && !user.expired) {
          // The oidc user is authenticated and the token is valid
          const decodedToken = jwt_decode(user.access_token);
          commit("userDataUpdate", {
            isInitialized: true,
            isAuthenticated: true,
            user: user,
            roles: decodedToken.role,
          });
        } else {
          // User is initialized but not authenticated
          commit("userDataUpdate", {
            isInitialized: true,
            isAuthenticated: false,
            user: null,
            roles: [],
          });
        }
      } catch {
        await store.dispatch("userLogout");
      }
    },
    userUpdate({ commit }, user) {
      const decodedToken = jwt_decode(user.access_token);

      commit("userDataUpdate", {
        isInitialized: true,
        isAuthenticated: true,
        user: user,
        roles: decodedToken.role,
      });
    },
    async userLogin(_, path) {
      await oidcmgr.signinRedirect({ state: path });
    },
    async userLogout() {
      await oidcmgr.signoutRedirect();
    },
  },
});

export default store;
