import store from "@/services/store";
import Oidc from "oidc-client";
import { configValue } from "@/services/config";

const appEndpoint = configValue("VUE_APP_SKA_VUE_EP");
const authEndpoint = configValue("VUE_APP_SKA_AUTH_EP");
const authClient = configValue("VUE_APP_SKA_AUTH_CLIENT");

var oidcmgr = new Oidc.UserManager({
  authority: authEndpoint,
  client_id: authClient,
  redirect_uri: appEndpoint + "/callback",
  response_type: "id_token token",
  scope: "openid profile offline_access api1",
  post_logout_redirect_uri: appEndpoint,
  userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),

  // the following works with the .addAccessTokenExpiring() call below to
  // refresh tokens 5 minutes before they expire.
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 60 * 5,
  silent_redirect_uri: appEndpoint + "/static/auth/renew.html",

  loadUserInfo: true,
});

// Triggered when `accessTokenExpiringNotificationTime` remains
// Suppress all errors until `AccessTokenExpired` except for `login_required`
oidcmgr.events.addAccessTokenExpiring(() =>
  userSigninSilent().catch((e) => {
    if (e.error === "login_required") {
      userSignOut();
    }
  })
);

// Try to sign in silent one last time and logout if there are any errors
oidcmgr.events.addAccessTokenExpired(() => userSigninSilent().catch(userSignOut));

// Triggered when the user is logged out in identity server
oidcmgr.events.addUserSignedOut(userSignOut);

function userSigninSilent() {
  return oidcmgr.signinSilent().then((user) => {
    return store.dispatch("userUpdate", user);
  });
}

function userSignOut() {
  return store.dispatch("userLogout");
}

if (process.env.NODE_ENV === "development") {
  Oidc.Log.logger = console;
  Oidc.Log.level = Oidc.Log.INFO;
}

export default oidcmgr;
