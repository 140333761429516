import Vue from "vue";
import Debounce from "lodash.debounce";
import VueSilentbox from "vue-silentbox";
import Clipboard from "v-clipboard";
import Toasted from "vue-toasted";
import router from "@/router";
import store from "@/services/store";
import { createApplicationInsight, createApplicationInsights } from "@/services/application-insight";
import App from "@/views/App.vue";
import VueScrollTo from "vue-scrollto";
import vuetify from "./plugins/vuetify";
import { createGlobalErrorHandlerPlugin } from "@/plugins/global-error-handler";

Vue.use(VueSilentbox);
Vue.use(Clipboard);
Vue.use(Toasted);
Vue.use(Debounce);
Vue.config.productionTip = false;
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.mixin({
  methods: {
    globalFormatBytes(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
        dm = decimals || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
});

const { applicationInsights, applicationInsightsPlugin } = createApplicationInsights(store, router);

Vue.use(applicationInsightsPlugin);

if (process.env.NODE_ENV === "production") {
  Vue.use(createGlobalErrorHandlerPlugin(), { applicationInsights });
}

new Vue({
  store: store,
  router: router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
