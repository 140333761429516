<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h1>Logga in</h1>
        <p>För att fortsätta krävs det att du är inloggad, klicka på knappen nedan för att komma till inloggningen.</p>

        <router-link tag="button" to="/signin" class="btn btn-primary" type="button">Logga in</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/services/store";

export default {
  async created() {
    if (store.state.userData.isAuthenticated) {
      this.$router.push({ name: "start" });
    } else {
      this.$router.push({ name: "signin" });
    }
  },
  beforeCreate: function () {
    document.body.classList.add("dark-layout");
  },
  destroyed: function () {
    document.body.classList.remove("dark-layout");
  },
};
</script>
<style scoped>
/* 



h1 {
    font-family: Roboto;
    font-weight: 800;
    font-style: italic;
    font-size: 57px;
    letter-spacing: -1.42px;
    color: #ffffff;
    color: rgb(255, 255, 255);
}
.btn-primary {
    transition: all ease-in-out 0.2s;
    background-color: rgba(99, 164, 187, 0.7);
    font-size: 1rem;
    border-radius: 0;
    border: 0;
    margin-top: 1rem;
}
.btn-primary:hover {
    background-color: #212121;
} */
</style>
