import Vue from "vue";
import Router from "vue-router";
import store from "@/services/store";
import Splash from "@/views/Splash.vue";
import Signin from "@/views/auth/Signin.vue";
import Callback from "@/views/auth/Callback.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import Signout from "@/views/auth/Signout.vue";

Vue.use(Router);

let r = new Router({
  mode: "history",
  routes: [
    { path: "/", name: "splash", component: Splash },
    {
      path: "/health",
      name: "health",
      meta: { skipUserInit: true },
      component: import("@/views/Health.vue"),
    },

    {
      path: "/signin",
      name: "signin",
      meta: { skipUserInit: true },
      component: Signin,
    },
    {
      path: "/test",
      name: "test",
      meta: { requiresAuth: true },
      component: () => import("@/views/Testar.vue"),
    },
    {
      path: "/callback",
      name: "callback",
      meta: { skipUserInit: true },
      component: Callback,
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      meta: { skipUserInit: true },
      component: Unauthorized,
    },
    {
      path: "/noaccess",
      name: "noaccess",
      meta: {},
      component: () => import("@/views/auth/NoAccess.vue"),
    },
    {
      path: "/signout",
      name: "signout",
      meta: { skipUserInit: true },
      component: Signout,
    },
    {
      path: "/fromapp",
      name: "fromapp",
      meta: { requiresAuth: true },
      component: () => import("@/views/StartFromApp.vue"),
    },
    {
      path: "/start",
      name: "start",
      meta: { requiresAuth: true, hideSubHeader: true },
      component: () => import("@/views/Start.vue"),
    },
    {
      path: "/ska",
      name: "ska",
      meta: { requiresAuth: true, title: "Ärenden" },
      props: { component: "overview", tab: 1 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/area",
      name: "skaarea",
      meta: { requiresAuth: true, title: "Ytor" },
      props: { component: "area", tab: 1 },
      component: () => import("@/views/Index.vue"),
    },

    {
      path: "/ska/room",
      name: "skaroom",
      meta: { requiresAuth: true, title: "Utrymmen" },
      props: { component: "room", tab: 1 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/actions",
      name: "ska-actions",
      meta: { requiresAuth: true, title: "Åtgärder" },
      props: { component: "overview", tab: 2 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/media",
      name: "ska-media",
      meta: { requiresAuth: true, title: "Rapporter" },
      props: { component: "overview", tab: 3 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/meps",
      name: "ska-meps",
      meta: { requiresAuth: true, title: "Meps" },
      props: { component: "overview", tab: 4 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/images",
      name: "ska-images",
      meta: { requiresAuth: true, title: "Bilder" },
      props: { component: "overview", tab: 5 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/estimate",
      name: "ska-estimate",
      meta: { requiresAuth: true, title: "Kalkyler" },
      props: { component: "overview", tab: 6 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/planactions",
      name: "ska-planactions",
      meta: { requiresAuth: true, title: "Arbetsberedning" },
      props: { component: "overview", tab: 7 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/admin",
      name: "ska-admin",
      meta: { requiresAuth: true, title: "Administrera" },
      props: { component: "overview", tab: 8 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/objekt",
      name: "ska-objekt",
      meta: { requiresAuth: true, title: "Objekt" },
      props: { component: "overview", tab: 11 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/ska/areas",
      name: "ska-arealist",
      meta: { requiresAuth: true, title: "Ytor" },
      props: { component: "overview", tab: 12 },
      component: () => import("@/views/Index.vue"),
    },
    {
      path: "/avf",
      name: "avf",
      meta: { requiresAuth: true, title: "Ärenden" },
      props: { component: "overview", tab: 1 },
      component: () => import("@/views/IndexAvf.vue"),
    },
    {
      path: "/avf/area",
      name: "avfarea",
      meta: { requiresAuth: true, title: "Ytor" },
      props: { component: "area", tab: 1 },
      component: () => import("@/views/IndexAvf.vue"),
    },
    {
      path: "/avf/media",
      name: "avf-media",
      meta: { requiresAuth: true, title: "Rapporter" },
      props: { component: "overview", tab: 3 },
      component: () => import("@/views/IndexAvf.vue"),
    },
    {
      path: "/log",
      name: "log",
      meta: { requiresAuth: true, title: "Loggar" },
      props: { component: "overview", tab: 1 },
      component: () => import("@/views/IndexLog.vue"),
    },
    {
      path: "/admin/dashboard",
      name: "dashboard",
      meta: { requiresAuth: true, title: "Dashboard", hideSubHeader: true },
      component: () => import("@/views/admin/Dashboard.vue"),
    },
    {
      path: "/admin/dashboard2",
      name: "dashboard2",
      meta: { requiresAuth: true, title: "Dashboard2", hideSubHeader: true },
      component: () => import("@/views/admin/Dashboard2.vue"),
    },
    {
      path: "/admin/answers",
      name: "answers",
      meta: { requiresAuth: true, title: "Answers" },
      component: () => import("@/views/admin/Answers.vue"),
    },
    {
      path: "/reportview",
      name: "reportview",
      meta: { requiresAuth: true, title: "Basrapport" },
      props: { ep: "report" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportbygg",
      name: "reportbyggview",
      meta: { requiresAuth: true, title: "Byggrapport" },
      props: { ep: "reportbygg" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportmeasure/reportview",
      name: "reportmeasureview",
      meta: { requiresAuth: true, title: "Mätprotokoll flersidigt" },
      props: { ep: "reportmeasure" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportmeasuresingle/reportview",
      name: "reportmeasuresingleview",
      meta: { requiresAuth: true, title: "Mätprotokoll" },
      props: { ep: "reportmeasuresingle" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportmoisturemalmo/reportview",
      name: "reportmoisturemalmo",
      meta: { requiresAuth: true, title: "Fuktutredning" },
      props: { ep: "reportmoisturemalmo" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportextended/reportview",
      name: "reportextended",
      meta: { requiresAuth: true, title: "Utökad rapport" },
      props: { ep: "reportextended" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportwaterdamagesurvey/reportview",
      name: "reportwaterdamagesurvey",
      meta: { requiresAuth: true, title: "Utökad skadebeskrivning" },
      props: { ep: "reportwaterdamagesurvey" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportconcept/reportview",
      name: "reportconcept",
      meta: { requiresAuth: true, title: "Konceptrapport" },
      props: { ep: "reportconcept" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportsanering/reportview",
      name: "reportsanering",
      meta: { requiresAuth: true, title: "Saneringsrapport" },
      props: { ep: "reportsanering" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportsaneringcompact/reportview",
      name: "reportsaneringcompact",
      meta: { requiresAuth: true, title: "Saneringsrapport (Kompakt)" },
      props: { ep: "reportsaneringcompact" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportactions/reportview",
      name: "reportactions",
      meta: { requiresAuth: true, title: "Åtgärdsrapport" },
      props: { ep: "reportactions" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportavfuktning/reportview",
      name: "reportavfuktning",
      meta: { requiresAuth: true, title: "Avfuktningsrapport" },
      props: { ep: "reportavfuktning" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/reportfrilaggning/reportview",
      name: "reportfrilaggning",
      meta: { requiresAuth: true, title: "Friläggningsprotokoll" },
      props: { ep: "reportfrilaggning" },
      component: () => import("@/views/report/ReportView.vue"),
    },
    {
      path: "/slides/report",
      name: "reportslide",
      meta: { requiresAuth: true, title: "Slides: Basrapport" },
      component: () => import("@/views/slides/Report.vue"),
    },
    {
      path: "/slides/reportedit",
      name: "reporteditslide",
      meta: { requiresAuth: true, title: "Slides: Redigera rapport" },
      component: () => import("@/views/slides/ReportEdit.vue"),
    },
    {
      path: "/slides/sanreportapp",
      name: "sanreportapp",
      meta: { requiresAuth: true, title: "Slides: Saneringsrapport och App" },
      component: () => import("@/views/slides/SanReportApp.vue"),
    },
    {
      path: "/slides/sanreport",
      name: "sanreport",
      meta: { requiresAuth: true, title: "Slides: Saneringsrapport" },
      component: () => import("@/views/slides/SanReport.vue"),
    },
    {
      path: "/slides/skasan",
      name: "skasan",
      meta: {
        requiresAuth: true,
        title: "Slides: Skadedokumentation Sanering",
      },
      component: () => import("@/views/slides/SkaSan.vue"),
    },
    {
      path: "/slides/mepsweb",
      name: "mepswebslide",
      meta: { requiresAuth: true, title: "Slides: Meps Webb" },
      component: () => import("@/views/slides/MepsWeb.vue"),
    },
    {
      path: "/slides/mepsapp",
      name: "mepsappslide",
      meta: { requiresAuth: true, title: "Slides: Meps App" },
      component: () => import("@/views/slides/MepsApp.vue"),
    },
    {
      path: "/slides/ska",
      name: "skaslide",
      meta: { requiresAuth: true, title: "Slides: Ska" },
      component: () => import("@/views/slides/Ska.vue"),
    },
    {
      path: "/slides/reportmeasuresingle",
      name: "reportmeasuresingleslide",
      meta: { requiresAuth: true, title: "Slides: Mätprotokoll" },
      component: () => import("@/views/slides/ReportMeasureSingle.vue"),
    },
    {
      path: "/workorder/redirect",
      name: "workorder-redirect",
      meta: { requiresAuth: true },
      component: () => import("@/views/Redirect.vue"),
    },
  ],
});

r.beforeEach(async (to, from, next) => {
  // ensure that store.state.userData is initialized with any current user and roles
  if (to.matched.some((record) => record.meta.requiresAuth) || to.matched.some((record) => !record.meta.skipUserInit)) {
    // we need this if requiresAuth == true but may otherwise skip it if skipUserInit == true
    await store.dispatch("userInit");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.userData.isAuthenticated) {
      if (!store.state.userData.roles.includes("se.eaztimate.hubb")) {
        r.push({ name: "noaccess" });
        next(false);
        return;
      }
      next();
      return;
    } else {
      await store.dispatch("userLogin", to.fullPath);

      next(false);
      return;
    }
  } else {
    next();
  }
});

export default r;
