var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.userData.isAuthenticated && !_vm.hideHeader)?_c('nav',{staticClass:"navbar navbar-expand-sm fixed-top navbar-eaztimate"},[_c('v-container',{attrs:{"fluid":""}},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"https://ezadmin.se","target":"_blank"}},[_c('svg',{staticStyle:{"enable-background":"new 0 0 24 24","width":"24px","height":"24px"},attrs:{"id":"Layer_1","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 24 24","xml:space":"preserve"}},[_c('g',{attrs:{"id":"Ez"}},[_c('path',{staticClass:"st0",attrs:{"d":"M12.24,1.12c6.14,0,11.13,4.99,11.13,11.14c0,6.14-4.99,11.14-11.13,11.14c-6.14,0-11.14-5-11.14-11.14\n\t\tC1.11,6.11,6.1,1.12,12.24,1.12 M12.24,21.98c5.36,0,9.72-4.36,9.72-9.72c0-5.36-4.36-9.72-9.72-9.72c-5.36,0-9.72,4.36-9.72,9.72\n\t\tC2.52,17.61,6.88,21.98,12.24,21.98"}}),_c('path',{staticClass:"st0",attrs:{"d":"M18.47,12.84c0,3.44-2.79,6.23-6.23,6.23c-3.44,0-6.23-2.79-6.23-6.23H18.47z"}})])])]),_c('ul',{staticClass:"ez-navbar pl-0"},[_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":"/start","active-class":"active"}},[(_vm.userData.roles.includes('se.eaztimate.ska'))?_c('a',{staticClass:"nav-link"},[_vm._v("Start")]):_vm._e()]),_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":"/ska","active-class":"active"}},[(_vm.userData.roles.includes('se.eaztimate.ska'))?_c('a',{staticClass:"nav-link"},[_vm._v("Ärenden")]):_vm._e()]),(
              _vm.userData.roles.includes('se.eaztimate.ska.admin') ||
              _vm.userData.roles.includes('se.eaztimate.ska.kvalitet') ||
              _vm.userData.roles.includes('admin') ||
              _vm.userData.roles.includes('superadmin') ||
              _vm.userData.roles.includes('groupadmin')
            )?_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":"/admin/dashboard","active-class":"active"}},[_c('a',{staticClass:"nav-link"},[_vm._v("Dashboard")])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://support.ezadmin.se"}},[_vm._v("Support")])]),_c('li',[(_vm.userData.roles.includes('se.eaztimate.ska.dev'))?_c('span',{staticClass:"role-tag"},[_c('i',{staticClass:"fas fa-code"}),_vm._v(" "),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(" DEV")])]):_vm._e(),(_vm.userData.roles.includes('se.eaztimate.ska.alfa'))?_c('span',{staticClass:"role-tag"},[_c('i',{staticClass:"fas fa-atom"}),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(" Alfa")])]):_vm._e(),(_vm.userData.roles.includes('se.eaztimate.ska.beta'))?_c('span',{staticClass:"role-tag"},[_c('i',{staticClass:"fas fa-flask"}),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(" Beta")])]):_vm._e()]),_c('li',{staticClass:"ez-navbar-signout",staticStyle:{"position":"absolute","right":"0"}},[_c('router-link',{staticClass:"d-inline-block float-right scrollcol-signout role-tag",attrs:{"to":"/signout"}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v(_vm._s(_vm.userData.user.profile.name))])])],1)],1)])],1):_vm._e(),(_vm.userData.isAuthenticated && !_vm.hideSubHeader)?_c('nav',{staticClass:"fixed-under navbar-eaztimate"},[_c('div',{attrs:{"id":"navbar2"}},[_c('ul',{staticClass:"navbar-nav align-items-start pl-0"},[_vm._t("navbar-frame"),_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":{ name: 'ska', query: { id: _vm.$route.query.id } },"active-class":"active"}},[_c('a',{staticClass:"nav-link"},[_vm._v("Skadedokumentation")])]),_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":{ name: 'avf', query: { id: _vm.$route.query.id } },"active-class":"active"}},[_c('a',{staticClass:"nav-link"},[_vm._v("Avfuktning")])]),(
              _vm.userData.roles.includes('se.eaztimate.ska.dev') &&
              (_vm.userData.roles.includes('se.eaztimate.ska.admin') ||
                _vm.userData.roles.includes('se.eaztimate.ska.kvalitet') ||
                _vm.userData.roles.includes('admin') ||
                _vm.userData.roles.includes('superadmin') ||
                _vm.userData.roles.includes('groupadmin'))
            )?_c('router-link',{staticClass:"nav-item",attrs:{"tag":"li","to":{ name: 'log', query: { id: _vm.$route.query.id } },"active-class":"active"}},[_c('a',{staticClass:"nav-link role-tag-color"},[_c('i',{staticClass:"fas fa-code",staticStyle:{"font-size":"0.75rem"}}),_vm._v(" Loggar ")])]):_vm._e()],2)])]):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }